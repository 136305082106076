import { storage, isSubset, API_BASE } from '@/utils'

const PUBLIC_ENDPOINTS = [
    'v1/GetCultures',
    'v1/GetTranslations',
    'v2/regions/data',
    /^v2\/organisations\/[a-z0-9-]{36}\/anonymous$/,
    /^v2\/organisations\/[a-z0-9-]{36}\/clinical-departments$/,
    /^v2\/organisations\/[a-z0-9-]{36}\/draft-users$/,
    /^v2\/organisations\/[a-z0-9-]{36}\/training\/tests$/,
    /^v2\/organisations\/[a-z0-9-]{36}\/training\/tests\/[a-z0-9-]{36}$/,
    /^v2\/organisations\/[a-z0-9-]{36}\/training\/tests\/[a-z0-9-]{36}\/start$/,
    /^v2\/organisations\/[a-z0-9-]{36}\/draft-users\/[a-z0-9-]{36}\/training\/tests\/complete$/,
    /^v2\/organisations\/[a-z0-9-]{36}\/draft-users\/[a-z0-9-]{36}\/training\/certificates\/[a-z0-9-]{36}\/download$/,
    /^v2\/organisations\/[a-z0-9-]{36}\/draft-users\/[a-z0-9-]{36}\/training\/certificates\/[a-z0-9-]{36}\/download-image$/
]

let refreshTokenSingleton

const refreshTokens = async (token) => {
    const response = await fetch(`${API_BASE}/v1/RefreshToken`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({refreshToken: token.refresh_token})
    }).catch(error => {
        console.error('API Error:', error)
    })

    if (response && response.ok) {
        const json = await response.json().catch(error => console.error('JSON API error', error))
        storage.set('token', {
            ...token,
            expires_on: new Date(new Date().getTime() + (json.data.expires_in * 1000)),
            access_token: json.data.access_token,
            refresh_token: json.data.refresh_token
        })
    } else {
        console.error('Bad or no response from token refresh call. Removing token.')
        storage.remove('token')
    }
}

const ABORT_CONTROLLERS = new Map()

const getAbortController = (key, method) => {
    const newController = new AbortController()
    if (key && method == 'GET') {
        ABORT_CONTROLLERS.set(key, newController)
    }
    return newController
}

const abortAllRequests = () => {
    for (let controller of ABORT_CONTROLLERS.values()) {
        controller.abort('Aborting pending requests due to auth status change')
    }
}

const resetAndRequestLogin = () => {
    abortAllRequests()
    storage.remove('token')
    storage.remove('user')
    storage.remove('region')
    if (window.location.pathname !== '' && window.location.pathname !== '/' && !window.location.pathname.endsWith('/login/')) {
        window.location.href = '/'
    }
}

export const callAPI = async (endpoint, method, params, filter, abortKey) => {
    let token = storage.get('token')
    const headers = {
        'Content-Type': method === 'PATCH' ? 'application/json-patch+json' : 'application/json'
    }

    if (!PUBLIC_ENDPOINTS.some(pe => endpoint.match(pe))) {
        if (token['expires_on'] && (new Date(token.expires_on) < new Date())) {
            console.debug('Auth token expired so refreshing')
            if (!refreshTokenSingleton) {
                refreshTokenSingleton = refreshTokens(token)
            }
            await refreshTokenSingleton
            refreshTokenSingleton = null
            token = storage.get('token')
        }
        if (!token) {
            console.warn('Attempt to call non public endpoint with missing/expired token. Bailing so can login.', endpoint)
            resetAndRequestLogin()
            throw Error('API BAIL')
        }
    }

    // If have token pass it no matter what as some endpoints are public and private
    // and response with different results based on whether logged in or not...
    if (token && token.access_token) {
        headers['Authorization'] = `Bearer ${token.access_token}`
    }

    const searchParams = (method === 'GET' && params) ? '?' + new URLSearchParams(params) : ''
    const apiURL = `${API_BASE}/${endpoint}${searchParams}`
    const controller = getAbortController(abortKey || apiURL, method) 

    const response = await fetch(apiURL, {
        method: method,
        headers: headers,
        signal: controller.signal,
        body: (method !== 'GET' && params) ? JSON.stringify(params) : null
    }).catch(error => {
        console.debug('DEBUG API ERROR', error.name, error)
        console.error('ERROR CALLING API', apiURL, error)
        throw( error )
    })

    if (response) {
        if (response.ok) {
            const contenType = response.headers.get("content-type") || 'unknown'
            if (contenType.startsWith('application/json')) {
                const json = await response.json().catch(error => console.error('JSON API error', error)) // TODO: Proper error handling
                // For v1 endpoints...
                if (json &&  json.data !== undefined && json.data !== null) {
                    if (json.data instanceof Array && filter && filter instanceof Object) {
                        return json.data.filter((record) => isSubset(record, filter))
                    } else {
                        return json.data
                    }
                // For v2 endpoints...
                } else if (json && json instanceof Array && filter && filter instanceof Object) {
                    return json.filter((record) => isSubset(record, filter))
                // For any oddities...
                } else {
                    return json
                }
            } else if (['application/pdf', 'image/jpeg'].includes(contenType)) {
                const blob = await response.blob()
                return blob
            } else {
                console.warn('API response had unsupported content type header so returning generic JSON success data')
                return {
                    success: true
                }
            }
        } else if (response.status === 400) {
            return {
                success: false,
                errorCode: 400,
                errors: response.errors
            }
        } else if (response.status === 401) {
            console.error('Unauthorised response from API so bailing to re-request login.', endpoint)
            resetAndRequestLogin()
        } else if (response.status === 409) {
            return {
                success: false,
                errorCode: 409
            }
        } else {
            throw( new Error(`Unexpected error retrieving data (status: ${response.status})`) )
        }
    } else {
        throw( new Error('No response from API') )
    }
}

const getUploadURL = async (id, fileType) => {
    return callAPI('v1/GenerateSasUrl', 'POST', {fileId: id, type: fileType})
}

export const uploadFile = async (file, id, fileType) => {
    // TODO:API Would rather just pass original mime type rather than have to fiddle with it here
    fileType = fileType || file
        .type
        .replace('image/', '')
        .replace('application/', '')
        .replace('jpeg', 'jpg')
        .toUpperCase()

    const uploadURL = await getUploadURL(id, fileType)

    await fetch(uploadURL.uri, {
        method: uploadURL.method,
        headers: uploadURL.headersToInclude.reduce( (a, {key, value}) => ({ ...a, [key]: value}), {} ),
        body: file
    })
    
    return Date.now() // for cache busting
}

export const stub200API = async (endpoint, method, params) => {
    console.debug('Stubbing API call with params: ', JSON.stringify(params))
    return new Promise((resolve) => {
        resolve({
            successfully: true,
            data: {

            }
        })
    })
}

export const stub501API = async (endpoint, method, params) => {
    console.debug('Stubbing API call with params: ', JSON.stringify(params))
    return new Promise((resolve) => {
        resolve({
            successfully: false,
            errorCode: 501,
            errorMsg: 'Not implemented yet'
        })
    })
}

export const sendContactForm = async (params) => {
    return callAPI('v1/SendContactForm', 'POST', params)
}

export const localStorageProvider = () => {
    const map = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'))
    window.addEventListener('beforeunload', () => {
      const appCache = JSON.stringify(Array.from(map.entries()))
      localStorage.setItem('app-cache', appCache)
    })
    return map
}

/**
 * Genereate a list of patch operations to mutate one onject into another
 * @param {Object} original the orginal object to use as a base for comparison
 * @param {Object} incoming the target object to create a patch to achieve
 * @returns {Array} list of patch operations that can be passed to API to mutate from original to incoming object
 */
export const generateSimplePatch = (original, incoming) => {
    // Check if two things differ, allowing for the things to be arrays
    const differ = (a, b) => {
        if (a instanceof Array && b instanceof Array) {
            return a.length !== b.length || !Object.keys(a).every(key => a[key] === b[key])
        } else {
            return a !== b
        }
    }

    // Generate value from key allowing for array of IDs or Objetcs (and transforming into format API expects)
    const generateValue = (key) => {
        if (incoming[key] instanceof Array) {
            if (incoming[key][0] instanceof Object) {
                return incoming[key]
            } else {
                return incoming[key].map(id => ({ id: id }))
            }
        } else {
            return incoming[key]
        }
    }

    return Object
        .keys(incoming)
        .filter(key => incoming[key] !== null && differ(incoming[key], original[key]))
        .map(key => ({ op: "replace", path: `/${key.replaceAll('.', '/')}`, value: generateValue(key) }))
}

export * from './audit'
export * from './clinical-settings'
export * from './countries'
export * from './disinfection-log'
export * from './email-templates'
export * from './handsets'
export * from './languages'
export * from './login'
export * from './manufacturers'
export * from './medical-devices'
export * from './organisations'
export * from './products'
export * from './regions'
export * from './reporting'
export * from './resources'
export * from './training'
export * from './translations'
export * from './users'